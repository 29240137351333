@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  html {
    font-family: bebas-neue-pro-expanded, sans-serif;
    font-weight: 400;
    font-style: normal;
    @apply text-secondary;
    box-sizing: border-box;

  }
}

::-moz-selection {
  /* Code for Firefox */
  color: #ffffff;
  background: #00A6ED;
}

::selection {
  color: #ffffff;
  background: #00A6ED;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

*:not(input):not(textarea) {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@layer base {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-display;
    font-style: normal;
    /* text-transform: uppercase;
    @apply text-primary; */
  }

  h1 {
    @apply text-5xl;
    font-weight: 700;
  }

  h2 {
    @apply text-4xl;
    font-weight: 700;
  }

  h3 {
    @apply text-3xl;
  }

  h4 {
    @apply text-2xl;
  }

  h5 {
    @apply text-xl;
  }

  h5 {
    @apply text-lg;
  }

  a {
    position: relative;
    text-decoration: none !important;
  }

  p a::after {
    pointer-events: none;
    position: absolute;
    left: 0;
    bottom: -3px;
    content: "";
    height: 3px;
    width: 100%;
    background-color: #f7ff07;
    transform-origin: bottom left;
  }

  p a:hover {
    color: #3e3e3d;
  }

  p a:hover::after {
    animation-name: underline;
    animation-duration: 300ms;
  }
}

main {
  /* overflow-x: hidden; */
}

.cursor {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  z-index: 5000;
  width: 30px;
  height: 30px;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  /* border-radius: 100%;;
  display: block;
  border-width: 10px;
  @apply border-tertiary */
}

/* 
.cursor-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
  transform: translate(-50%, -50%);
} */

.text-title {
  font-size: 16vw;
  font-size: max(54px, 16vw);
  text-transform: uppercase;
  line-height: 90%;
  font-weight: 700;
  @apply font-display;
}

.text-nav {
  font-size: 15vw !important;
  font-size: max(54px, 15vw) !important;
  text-transform: uppercase;
  line-height: 90%;
  font-weight: 700;
  @apply font-display;
}

.title-static {
  font-size: 12vw;
  font-size: max(54px, 12vw);
  text-transform: uppercase;
  line-height: 80%;
  font-weight: 700;
  @apply font-display;
}

.container {
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  max-width: 100%;
}

.media-fill {
  position: relative;
  width: 100%;
}

.form-field {
  margin-bottom: 1rem;
  padding-left: .5rem;
  padding-right: .5rem;
}

.form-field .form-field-label {
  display: flex;
  align-items: center;
}

.form-input {
  width: 100%;
  padding: .75rem 1rem;
  font-size: 1.2rem;
  border-radius: .25rem;
  @apply bg-light-gray;
}

.form-wrapper.form-wrapper-compact .form-input {
  font-size: .85rem;
  padding: .5rem;
}

.form-input.form-input-select {
  padding: 0;
}

.form-input.form-input-select  .select__control {
  width: 100%;
  padding: .75rem 1rem;
  font-size: 1.2rem;
  border-radius: .25rem;
  border-width: 0;
  box-shadow: none;
  @apply bg-light-gray;
}

.form-wrapper.form-wrapper-compact .select__control {
  font-size: .85rem;
  padding: .5rem;
}

.form-wrapper.form-wrapper-compact .select__indicator {
  padding: .25rem;
}

.form-input:focus, .form-input.form-input-select .select__control:focus {
  outline-width: 2px;
  @apply outline-primary-100;
}

.form-input.form-input-select .select__placeholder {
  color: #9ca3af;
}

.form-input.form-input-select .select__control .select__multi-value {
  @apply bg-primary;
  @apply text-white;
}

.form-input.form-input-select .select__control .select__multi-value__label {
  @apply text-white;
}


.form-wrapper .form-tabs {
  margin-bottom: 1rem;
}

.form-wrapper .form-tabs button {
  padding: .5rem 1rem;
  font-size: 1.25rem
}

.form-wrapper.form-wrapper-compact .form-tabs button {
  font-size: 1rem;;
}

.form-wrapper .form-tabs button.active {
  font-weight: bold;
  border-bottom-width: 2px;
  @apply text-primary;
  @apply border-primary;
}

/* .form-wrapper .form-tabs button:focus {
  @apply border-t-secondary;
  @apply border-t-2;
  outline-width: 0;
} */

.form-wrapper .form-tabs button:disabled {
  opacity: .3;
}

.form-wrapper [role="tabpanel"] {
  @apply bg-white;
  /* @apply shadow-lg; */
  border-radius: 1rem;
  @apply py-4;
  @apply px-1;
  @apply md:px-8;
}

.form-wrapper.form-wrapper-compact [role="tabpanel"] {
  padding: 1rem;
}


.logo-type {
  font-size: 5vw;
  font-size: max(54px, 5vw);
  text-transform: uppercase;
  line-height: 80%;
  font-weight: 700;
  @apply font-display;
}

.logo-type span {
  display: block;
}

.formkit-field input {
  background: transparent !important;
  border: transparent !important;
  border-bottom: #ffffff solid 3px !important;
  border-radius: 0 !important;
  font-size: 18px !important;
  color: #ffffff !important;
}

.formkit-submit span {
  font-weight: bold;
  font-size: 24px;
}

.formkit-powered-by-convertkit {
  display: none !important;
}

#footer-push {
  min-height: 800px;
  padding-bottom: 25vh;
  pointer-events: none;
}

footer {
  position: fixed;
  padding-top: 21vh;
}

footer .container {
  min-height: 560px;
}

.loader {
  height: 4px;
  width: 130px;
  --c: no-repeat linear-gradient(#00A6ED 0 0);
  background: var(--c), var(--c), #ffffff;
  background-size: 60% 100%;
  animation: l16 3s infinite;
}

@keyframes l16 {
  0% {
    background-position: -150% 0, -150% 0
  }

  66% {
    background-position: 250% 0, -150% 0
  }

  100% {
    background-position: 250% 0, 250% 0
  }
}

@media screen and (max-width: 1024px)  {
  #footer-push {
    min-height: auto;
    padding-bottom: 0;
  }

  footer {
    position: relative;
    padding-top: 0;
  }

  footer .container {
    height: auto;
    min-height: auto;
  }
}

@keyframes scrolling-text {
  0% {
    transform: translateX(-20%);
  }

  100% {
    transform: translateX(-120%);
  }
}

@keyframes scrolling-text-forward {
  0% {
    transform: translateX(20%);
  }

  100% {
    transform: translateX(120%);
  }
}